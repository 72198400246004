<script>
    let showModal = false;
    let modal;

    export let url = 'https://google.com';
    export let button_text;
    export let button_style = '';

    const handleClose = (event) => {
        if (event.target === modal) {
            closeModal();
        }
    };

    const closeModal = () => {
        document.body.style.overflow = '';
        showModal = false;
    };

    const openModal = () => {
        document.body.style.overflow = 'hidden';
        showModal = true;
    };
</script>


<a href="{url}" class="{button_style} modal-link" on:click|preventDefault={openModal}>{button_text}</a>

{#if showModal}
    <div class="modal" bind:this={modal} on:click={handleClose}>
        <div class="modal-content" on:click|stopPropagation>
            <iframe src={url}></iframe>
            <button on:click={closeModal}>Stäng</button>
        </div>
    </div>
{/if}

<style>
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        z-index: 9999;
    }

    .modal-content {
        z-index: 999;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 900px;
        height: 80vh;
        background: white;
    }

    .modal-content iframe {
        width: 100%;
        height: 100%;
    }

    @media (min-width: 600px) {
        .modal-content {
            width: 70%;
        }
    }

    @media (min-width: 900px) {
        .modal-content {
            width: 50%;
        }
    }
</style>